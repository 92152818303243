import URI from 'urijs';

const constants = {
  API_ENDPOINT: '/hurricane/api/',
  MAX_ENTRIES: 10,
  DEFAULT_FINGERPRINT: `12q34`,
  DEFAULT_CAMPAIGN: 'THUNDER-AND-LIGHTNING-2000'
};

const CONSTRUCT_CALL = (prefix, name, args) => {
  const paths = [
    prefix,
    name,
    ...args
  ];

  const queries = Array.isArray(paths.slice(-1).pop()) ? paths.pop() : null;
  const result = URI.joinPaths.apply(null, paths).toString();
  if (queries && queries.length) {
    return `${result}?${queries.filter(query => Boolean(query)).join('&')}`;
  }
  return result;
};


const MAKE_API = (name, ...args) => {
  return CONSTRUCT_CALL(constants.API_ENDPOINT, name, args);
};

const MAKE_REQUEST = (name, ...args) => {
  return CONSTRUCT_CALL('/', name, args);
};

export { MAKE_API, MAKE_REQUEST };
export default constants;