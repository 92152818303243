import { call, put, takeEvery, takeLatest, all, delay, throttle, select } from 'redux-saga/effects';

import * as actions from './actions';
import {
  getBallot,
  getCurrentCampaign,
  getCampaigns,
  isAppReady,
} from './selectors';

import {
  isVotingOpen,
  hasVotingEnded,
} from './getters';

import backend from './backend';

import AppController from '../Controllers/AppController';
import { preloadImage } from '../Utils/imageUtils';

const HEALTH_INTERVAL = 30000; // update every 30 seconds

function* navigate(action) {
  yield AppController.navigate(action.payload.location, action.payload.method || AppController.navigate.replace);
}

function* synchronizeModelWithRequest(action) {
  try {
    const campaigns = yield select(getCampaigns);
    const currentCampaign = yield select(getCurrentCampaign);
    const ready = yield select(isAppReady);

    if (!ready) {
      return;
    }

    const parts = action.payload.location ? action.payload.location.split('/').filter(part => part.length > 0) : [];

    let tool = parts.shift();

    if (tool === 'standings') {
      let year = parts.pop();
      // TODO: hasCampaignForYear

      if (!year || year === 'current') {
        year = new Date().getFullYear();

        yield put ({
          type: actions.NAVIGATE,
          payload: {
            location: `/${tool}/${year}`
          }
        });
      } else {
        yield put ({
          type: actions.FETCH_STANDINGS,
          payload: {
            year
          }
        });
        yield put ({
          type: actions.SET_ACTIVE_CATEGORY,
          payload: {
            category: ''
          }
        });
      }

      return;
    }

    if (tool === 'directory') {
      let year = parts.pop();
      // TODO: hasCampaignForYear

      if (!year || year === 'current') {
        year = new Date().getFullYear();

        yield put ({
          type: actions.NAVIGATE,
          payload: {
            location: `/${tool}/${year}`
          }
        });
      } else {
        yield put ({
          type: actions.FETCH_DIRECTORY_THUMBNAILS,
          payload: {
            year
          }
        });
      }

      return;
    }

    let campaignId = parts.pop();
    const camp = campaigns[campaignId];

    if (!campaignId || campaignId === 'current' || !camp) {
      if (!tool) {
        tool = 'vote';
      }

      if (!currentCampaign) {
        if (!campaignId && tool === 'coming') {
          yield put ({
            type: actions.SET_ACTIVE_CATEGORY,
            payload: {
              category: ''
            }
          });
          return;
        }
        yield put ({
          type: actions.NAVIGATE,
          payload: {
            location: `/coming`
          }
        });
      } else {
        campaignId = currentCampaign.id;
        if (tool === 'coming') {
          tool = 'vote';
        }
        yield put ({
          type: actions.NAVIGATE,
          payload: {
            location: `/${tool}/${campaignId}`
          }
        });
      }
      return;
    }

    if (tool === 'vote'&& hasVotingEnded(camp)) {
      yield put ({
        type: actions.NAVIGATE,
        payload: {
          location: `/gallery/${camp.id}`
        }
      });
      yield put ({
        type: actions.SET_ACTIVE_CATEGORY,
        payload: {
          category: 'all'
        }
      });
      return;
    }

    if (tool === 'embed') {
      yield put ({
        type: actions.FETCH_IMAGE_LIST,
        payload: {
          campaign: camp.id
        }
      });
      yield put ({
        type: actions.SET_ACTIVE_CATEGORY,
        payload: {
          category: 'all'
        }
      });

      return;
    }

    if (tool === 'gallery' && !hasVotingEnded(camp)) {
      yield put ({
        type: actions.NAVIGATE,
        payload: {
          location: `/vote/${camp.id}`
        }
      });
      yield put ({
        type: actions.SET_ACTIVE_CATEGORY,
        payload: {
          category: 'all'
        }
      });

      return;
    }

    if ((tool === 'vote' && isVotingOpen(camp)) || (tool === 'gallery' && hasVotingEnded(camp))) {
      yield put ({
        type: actions.FETCH_IMAGE_LIST,
        payload: {
          campaign: camp.id
        }
      });
    }

    yield put ({
      type: actions.SET_ACTIVE_CATEGORY,
      payload: {
        category: 'all'
      }
    });

    yield put ({
      type: actions.SET_ACTIVE_VIEW,
      payload: {
        view: 'carousel'
      }
    });

  } catch (error) {
    AppController.reportError('synchronizing datastore', error);
  }
}

function* fetchImages(action) {
  try {
    let campaign = yield select(getCurrentCampaign);

    if (action.payload.campaign && action.payload.campaign !== 'current') {
      campaign = action.payload.campaign;
    } else {
      campaign = campaign.id;
    }

    AppController.enterPotentiallyLengthyOperation();
    const images = yield call(backend.fetchImages, campaign);
    yield put({
      type: actions.INIT_IMAGE_LIST,
      payload: {
        images
      }
    });
    yield Promise.allSettled(images.map(image => preloadImage(image.url)));
    yield put({
      type: actions.SET_ACTIVE_CAMPAIGN,
      payload: {
        campaign
      }
    });
  } catch (error) {
    AppController.reportError('fetching images', error);
  } finally {
    AppController.exitPotentiallyLengthyOperation();
  }
}

function* fetchBallot() {
  try {
    const ballot = yield call(backend.fetchBallot);
    yield put({
      type: actions.INIT_BALLOT,
      payload: {
        ballot
      }
    });
  } catch (error) {
    AppController.reportError('fetching ballot', error);
  }
}

function* fetchStandings(action) {
  try {
    AppController.enterPotentiallyLengthyOperation();

    const standings = yield call(backend.fetchStandings, action.payload.year);
    yield put({
      type: actions.INIT_STANDINGS,
      payload: {
        standings
      }
    });
  } catch (error) {
    AppController.reportError('fetching standings', error);
  }  finally {
    AppController.exitPotentiallyLengthyOperation();
  }
}

function *fetchDirectoryThumbnails(action) {
  try {
    AppController.enterPotentiallyLengthyOperation();
    const directory = yield call(backend.fetchDirectory, action.payload.year);
    yield put({
      type: actions.INIT_DIRECTORY_THUMBNAILS,
      payload: {
        directory
      }
    });
  } catch (error) {
    AppController.reportError('fetching directory', error);
  }  finally {
    AppController.exitPotentiallyLengthyOperation();
  }
}

function* castBallot() {
  try {
    AppController.enterPotentiallyLengthyOperation();
    let ballot = yield select(getBallot);
    const campaign = yield select(getCurrentCampaign);

    ballot = {
      campaign: campaign.id,
      ballot: ballot.vote.ballot[campaign.id]
    };

    ballot = yield call(backend.recordBallot, ballot);
    yield put({
      type: actions.INIT_BALLOT,
      payload: {
        ballot
      }
    });
  } catch (error) {
    AppController.reportError('recording vote', error);
    // resync the ballot...
    yield put({ type: actions.FETCH_BALLOT });
  } finally {
    AppController.exitPotentiallyLengthyOperation();
  }
}

function* fetchHealth() {
  const health = yield call(backend.fetchHealth);
  yield put({
    type: actions.UPDATE_HEALTH,
    payload: {
      ...health,
      connectionStatus: 'connected',
    }
  });
}

function* initServerHealth(action) {
  const interval = (action && action.interval) || HEALTH_INTERVAL;

  while (true) {
    yield put({
      type: actions.FETCH_HEALTH
    });
    yield delay(interval);
  }
}

function* initialize(action) {
  try {
    const config = yield call(backend.fetchConfiguration);
    yield put({
      type: actions.SERVER_CONFIG,
      payload: {
        config,
      },
    });
    yield put({ type: actions.INIT_SERVER_HEALTH });
    if (action.payload && action.payload.location) {

      yield put({
        type: actions.NAVIGATE,
        payload: action.payload,
      });
    }
    yield put({ type: actions.FETCH_BALLOT });
    yield put({
      type: actions.UPDATE_READY_STATE
    });
    yield AppController.initialized();
  } catch (error) {
    AppController.reportError('initializing datastore', error);
  }
}

function* watchFetchHealth() {
  yield throttle(HEALTH_INTERVAL, actions.FETCH_HEALTH, fetchHealth);
}

function* watchInitServerHealth() {
  yield takeEvery(actions.INIT_SERVER_HEALTH, initServerHealth);
}

function* watchFetchImages() {
  yield takeEvery(actions.FETCH_IMAGE_LIST, fetchImages);
}

function* watchFetchBallot() {
  yield takeLatest(actions.FETCH_BALLOT, fetchBallot);
}

function* watchFetchStandings() {
  yield takeLatest(actions.FETCH_STANDINGS, fetchStandings);
}

function* watchFetchDirectoryThumbnails() {
  yield takeLatest(actions.FETCH_DIRECTORY_THUMBNAILS, fetchDirectoryThumbnails);
}

function* watchInitialize() {
  yield takeEvery(actions.INITIALIZE, initialize);
}

function* watchNavigate() {
  yield takeLatest(actions.NAVIGATE, navigate);
}

function* watchSynchronize() {
  yield takeEvery(actions.SYNCHRONIZE_DATA_MODEL_WITH_REQUEST,  synchronizeModelWithRequest);
}

function* watchVoting() {
  yield throttle(1000, [
    actions.RECORD_VOTE,
    actions.REMOVE_VOTE,
  ], castBallot);
}



export default function* rootSaga() {
  yield all([
    watchFetchBallot(),
    watchFetchStandings(),
    watchFetchDirectoryThumbnails(),
    watchFetchImages(),
    watchFetchHealth(),
    watchInitialize(),
    watchInitServerHealth(),
    watchNavigate(),
    watchVoting(),
    watchSynchronize(),
  ]);
}
