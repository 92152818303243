export const SET_ACTIVE_CAMPAIGN = 'hurricane/application/filters/setActiveCampaign';
export const RESET_ALL_FILTERS = 'hurricane/application/filters/clearAll';
export const SET_ACTIVE_CATEGORY = 'hurricane/application/filters/setActiveCategory';
export const SET_ACTIVE_VIEW = 'hurricane/application/view/set';
export const SET_EXPANDED_STATE = 'hurricane/application/expanded/set';

export const UPDATE_HEALTH = 'hurricane/application/health/update';
export const SERVER_CONFIG = 'hurricane/application/config';
export const UPDATE_READY_STATE = 'hurricane/application/ready-state/update';

export const INIT_STANDINGS = 'hurricane/standings/initialize';
export const INIT_DIRECTORY_THUMBNAILS = 'hurricane/directory/thumbs/initialize';

export const RESET_BALLOT = 'hurricane/ballot/reset';
export const INIT_BALLOT = 'hurricane/ballot/initialize';
export const RECORD_VOTE = 'hurricane/vote/record';
export const REMOVE_VOTE = 'hurricane/vote/remove';

export const RESET_IMAGE_LIST = 'hurricane/images/reset';
export const INIT_IMAGE_LIST = 'hurricane/images/initialize';

export const FETCH_IMAGE_LIST = 'hurricane-server/images/fetch';

export const FETCH_BALLOT = 'hurricane-server/ballot/fetch';
export const RECORD_BALLOT = 'hurricane-server/ballot/record';

export const FETCH_STANDINGS = 'hurricane-server/standings/fetch';
export const FETCH_DIRECTORY_THUMBNAILS = 'hurricane-server/directory/thumbs/fetch';

export const INIT_SERVER_HEALTH = 'hurricane-server/health/initialize';
export const FETCH_HEALTH = 'hurricane-server/health/fetch';

export const NAVIGATE = 'hurricane/navigate';
export const INITIALIZE = 'hurricane/initialize';
export const SYNCHRONIZE_DATA_MODEL_WITH_REQUEST = 'hurricane/synchronize';