import React from 'react';
import PropTypes from 'prop-types';
import { GlobalHotKeys } from 'react-hotkeys';

import CloseIcon from '@mui/icons-material/Close';

import { ManagedDialog } from '../../Controllers/DialogController';

import styles from './ImageLightbox.module.scss';

export default class ImageLightbox extends ManagedDialog {
  constructor(props) {
    super(props);

    this.keyMap = {
      Close: 'Escape'
    };

    this.handlers = {
      Close: this.props.onClose
    };
  }

  render() {
    return (
      <GlobalHotKeys keyMap={this.keyMap} handlers={this.handlers}>
        <div className={styles.lightbox}>
          <div className={styles.close} onClick={this.props.onClose}>
            <CloseIcon />
          </div>
          <div className={styles.container}>
            <img src={this.props.image.url} alt={this.props.image.category} />
          </div>
        </div>
      </GlobalHotKeys>
    );
  }

}

ImageLightbox.propTypes = {
  image: PropTypes.object.isRequired,
};
