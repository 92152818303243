
import * as actions from '../actions';

const NO_LISTING = {};

function initializeDirectory(state, directory) {
  return directory;
}

export default function images(state = NO_LISTING, action) {
  switch (action.type) {

    case actions.INIT_DIRECTORY_THUMBNAILS:
      return initializeDirectory(state, action.payload.directory);

    default:
      break;
  }

  return state;
}