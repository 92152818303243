import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RingLoader from 'react-spinners/RingLoader';
// import ReactDOM from 'react-dom';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';

import DialogController from '../../Controllers/DialogController';
import ImageLightbox from '../../Dialogs/ImageLightbox';

import styles from './ImageCatalogView.module.scss';

import { hasVotedForImage, getFilteredImages, testAddingMoreVotesToCategory } from '../../model/selectors';
import * as actions from '../../model/actions';

import Header from '../Header';
import Footer from '../Footer';

class ImageCatalogView extends Component {
  constructor(props) {
    super(props);

    this.renderImageCard = this.renderImageCard.bind(this);
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
    this.handleVoteForImage = this.handleVoteForImage.bind(this);

    this.state = {
      openSnackbar: false,
      snackbarMessage: null,
      snackbarSeverity: 'info'
    };
  }

  handleShowLightbox(image) {
    DialogController.showLightbox(<ImageLightbox
      image={image}
    />);
  }

  renderImageCard(image, index) {
    return (
      <Grid sx={{
        maxWidth: '345px',
      }}
        item
        key={`image-${index}`}
        xs={6}
      >
        <Card sx={{
          maxWidth: 345,
          minWidth: 300,
          maxHeight: 300,
          minHeight: 300,
          cursor: 'pointer',
          position: 'relative',
          '&:hover': {
            backgroundColor: '#1976d2',
            '*': {
              color: 'white',
              borderColor: 'white',
            },
          }
        }}
        onClick={() => this.handleShowLightbox(image)}>
          <CardMedia
            component="img"
            alt={"vote for this image"}
            height="200"
            image={image.url}
          />
          <div className={styles.cardContent}>
            <Typography variant="body2" color="text.secondary">
              {image.category}
            </Typography>
          </div>
          <CardActions>
            <Checkbox checked={this.props.hasVotedForImage(image)} onChange={() => this.handleVoteForImage(image)} onClick={(e) => {e.stopPropagation();}} />
            <Typography variant="h6" component="span">
              Vote for this item
            </Typography>
          </CardActions>
        </Card>
      </Grid>
    );
  }

  renderImageGrid() {
    const cards = this.filteredImages.map(this.renderImageCard);
    return (
      <Box sx={{
        position: 'relative',
        width: '100%',
        overflow: 'auto',
        height: `calc(var(--vph, 100vh) - var(--header-height) - var(--footer-height));`,
      }}>
        <Grid sx={{
          marginLeft: '10px',
          marginTop: 0,
          width: 'calc(100% - 16px)',
          paddingBottom: '100px',
          '@media(max-width: 786px)': {
            flexDirection: 'column',
          },
          '& .MuiGrid-item': {
            maxWidth: '375px',
            '@media(max-width: 786px)': {
              paddingLeft: 0,
              paddingRight: 0,
              marginLeft: 'auto',
              marginRight: 'auto',
            }
          }
        }}
          container
          rowSpacing={4}
          columnSpacing={8}
        >
          {cards}
        </Grid>
      </Box>
    );
  }

  renderLonelyContent() {
    return (
      <div className={styles.Catalog}>
        <div className={clsx(styles.helpText, styles.lonely)}>
          <SearchIcon fontSize="large" />
          <Typography variant="h6" color="inherit" component="div">
            Move along... Nothing to see here...
          </Typography>
        </div>
      </div>
    );
  }

  renderNoResultsContent() {
    return (
      <div className={styles.Catalog}>
        <div className={clsx(styles.helpText, styles.lonely)}>
          <SearchIcon fontSize="large" />
          <Typography variant="h6" color="inherit" component="div">
            Move along... Nothing to see here...
          </Typography>
        </div>
      </div>
    );
  }

  handleVoteForImage(image) {
    if (this.props.hasVotedForImage(image)) {
      this.props.downVoteImage(image);
    } else {
      const test = this.props.testAddingMoreVotesToCategory(image.category);
      if (!test) {
        this.props.upVoteImage(image);
      } else {
        this.setState({
          openSnackbar: true,
          snackbarMessage: test.message,
          snackbarSeverity: 'error',
        });
      }
    }
  }

  handleCloseSnackbar() {
    this.setState({ openSnackbar: false });
  }

  get filteredImages() {
    return this.props.getFilteredImages();
  }

  render() {
    let content;
    if (this.props.initializing) {
      content = (
        <div className={clsx(styles.loader, styles.Catalog)}>
            <RingLoader className={styles.loader} color="white" />
        </div>
      );
    } else if (this.props.images.length === 0) {
      content = this.renderLonelyContent();
    } else if (this.filteredImages.length === 0 && this.props.filter !== '') {
      content = this.renderNoResultsContent();
    } else {
      content = this.renderImageGrid();
    }

    return (
      <div className={styles.Catalog}>
        <Header/>
        {content}
        <Snackbar open={this.state.openSnackbar} autoHideDuration={6000} onClose={this.handleCloseSnackbar}>
          <Alert onClose={this.handleCloseSnackbar} severity={this.state.snackbarSeverity} sx={{ width: '100%' }}>
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
        <Footer/>
      </div>
    );
  }
}

ImageCatalogView.propTypes = {
  filter: PropTypes.string,
  images: PropTypes.array,
  initializing: PropTypes.bool.isRequired,
  campaign: PropTypes.object.isRequired,
  hasVotedForImage: PropTypes.func,
  getFilteredImages: PropTypes.func,
  upVoteImage: PropTypes.func,
  downVoteImage: PropTypes.func,

};

ImageCatalogView.defaultProps = {
  filter: '',
  images: [],
  campaign: {},
  initializing: true,
  hasVotedForImage: () => { },
  getFilteredImages: () => { },
  upVoteImage: () => { },
  downVoteImage: () => { },
  testAddingMoreImagesByCategory: () => { },
};

ImageCatalogView.connector = (state) => {
  const currentCampaign = state.appState.config.currentCampaign;
  const campaign = state.appState.config.campaigns[currentCampaign];
  const initializing = !state.appState.ready;

  return {
    images: Object.values(state.images),
    campaign,
    initializing,
    hasVotedForImage: (image) => {
      return hasVotedForImage(state, image);
    },
    getFilteredImages: () => {
      return getFilteredImages(state);
    },
    testAddingMoreVotesToCategory: (category) => {
      return testAddingMoreVotesToCategory(state, category);
    },

  };
};

ImageCatalogView.commander = (dispatch) => {
  return {
    upVoteImage: (image) => dispatch({ type: actions.RECORD_VOTE, payload: { image } }),
    downVoteImage: (image) => dispatch({ type: actions.REMOVE_VOTE, payload: { image } }),
  };
};


export default connect(
  ImageCatalogView.connector,
  ImageCatalogView.commander
)(ImageCatalogView);
