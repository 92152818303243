import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Dialog from '@mui/material/Dialog';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Help from '@mui/icons-material/Help';
import ReportProblem from '@mui/icons-material/ReportProblem';

import { HotKeys, GlobalHotKeys } from 'react-hotkeys';
import { makeHotKeyHandler } from '../Utils/MUIUtils';

import styles from './Dialog.module.scss';

let DIALOGS = {};
let MODAL_KEY = 1;

export class ManagedDialog extends Component {
  render() {
    return (
      <>
        {this.props.children}
      </>
    );
  }
}

ManagedDialog.propTypes = {
  onClose: PropTypes.func,
};

ManagedDialog.defaultProps = {
  onClose: () => { }
};

class PromptDialog extends ManagedDialog {
  constructor(props) {
    super(props);
    this.textField = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDismissButton = this.handleDismissButton.bind(this);

    this.keyMap = {
      OK: 'Enter',
      Cancel: 'Escape'
    };
    this.handlers = {
      OK: () => this.handleDismissButton(props.confirmLabel),
      Cancel: () => this.handleDismissButton(props.cancelLabel ? props.cancelLabel : props.confirmLabel)
    };

    this.hotKeyHandler = makeHotKeyHandler(this.keyMap, this.handlers);

    this.state = {
      val: props.value,
    };
  }

  handleChange(e) {
    this.setState({
      val: e.currentTarget.value,
    });
  }

  handleClick() {
    if (this.textField.current) {
      this.textField.current.focus();
    }
  }

  handleDismissButton(reason) {
    this.props.onClose(reason, this.state.val);
  }

  computeButtons() {
    const buttons = [];

    buttons.push(<Button variant="outlined" onClick={() => this.handleDismissButton(this.props.confirmLabel)} key="OK">{this.props.confirmLabel}</Button>);
    if (this.props.cancelLabel) {
      buttons.push(<Button variant="outlined" color="secondary" onClick={() => this.handleDismissButton(this.props.cancelLabel)} key="cancel">{this.props.cancelLabel}</Button>);
    }

    return buttons;
  }

  render() {

    const buttons = this.computeButtons();

    const content = (
      <div className={styles.prompt}>
        <span className={styles.text}>
          {this.props.text}
        </span>
        <div className={styles.input} onClick={this.handleClick} tabIndex="0" onFocus={this.handleClick}>
          <TextField
            ref={this.textField}
            sx={{ backgroundColor: 'white', color: 'black' }}
            fullWidth size="small"
            variant="outlined"
            autoFocus
            value={this.state.val}
            onChange={this.handleChange}
            onKeyDown={this.hotKeyHandler}
          />
        </div>
      </div>
    );

    return (
      <>
        <HotKeys keyMap={this.keyMap} handlers={this.handlers}>
          <DialogTitle>{this.props.dialogTitle}</DialogTitle>
          <DialogContent>
            {content}
          </DialogContent>
          <DialogActions>
            {buttons}
          </DialogActions>
        </HotKeys>
      </>
    );
  }
}

PromptDialog.propTypes = {
  dialogTitle: PropTypes.string,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.string,
};

PromptDialog.defaultProps = {
  dialogTitle: 'Prompt',
  confirmLabel: 'Dismiss',
  value: '',
  cancelLabel: null,
  text: null,
};

export default class DialogController {

  static add(dialog) {
    let node = document.createElement('div');
    document.body.appendChild(node);
    ReactDOM.render(dialog, node);
    DIALOGS[dialog.key] = node;
  }

  static remove(dialog) {
    if (DIALOGS[dialog]) {
      ReactDOM.unmountComponentAtNode(DIALOGS[dialog]);
      document.body.removeChild(DIALOGS[dialog]);
      delete DIALOGS[dialog];
    }
  }

  static doModal(dialog, propsIn) {
    const handleClose = dialog.props.onClose || (() => { });
    // create a close handler to hide the dialog
    const doClose = (reason, ...args) => {
      DialogController.remove(key);
      // call the original close handler
      handleClose.apply(null, [reason, ...args]);
    };
    let key = MODAL_KEY++;
    const props = {
      open: true,
      disableEscapeKeyDown: true,
      key,
      ...propsIn,
    };
    const wrappedDialog = React.createElement(
      Dialog,
      props,
      React.cloneElement(dialog, {
        onClose: doClose,
      })
    );

    DialogController.add(wrappedDialog);
    return key;
  }


  static showLightbox(lightbox, propsIn) {
    const handleClose = lightbox.props.onClose || (() => { });
    // create a close handler to hide the dialog
    const doClose = (reason, ...args) => {
      DialogController.remove(key);
      // call the original close handler
      handleClose.apply(null, [reason, ...args]);
    };
    let key = MODAL_KEY++;
    const props = {
      open: true,
      key,
      ...propsIn,
    };
    const wrappedDialog = React.createElement(
      Backdrop,
      props,
      React.cloneElement(lightbox, {
        onClose: doClose,
      })
    );

    DialogController.add(wrappedDialog);
    return key;
  }

  /**
   * Shows an alert dialog with simple text
   *
   * @param {String} text text content to show in the dialog
   * @param {String?} title dialog title
   * @param {String='silent'} type dialog type oneOf(['silent', 'yesNo', 'okCancel', 'ok', 'close' (default)])
   *  silent shows no buttons (escape closes the dialog)
   *  yesNo shows 'Yes' and 'No' buttons
   *  okCancel shows 'OK' and  'Cancel' buttons
   *  ok just shows a single 'OK' button
   *  close shows a 'Close' button
   * @returns {Promise} a promise which is resolved with the result ('ok', 'cancel', 'close', 'yes', 'no')
   */
  static doAlert(text, title, type = 'close') {
    const activeElement = document.activeElement;
    const restoreFocus = () => {
      if (activeElement) {
        activeElement.focus();
      }
    };
    const dialogTitle = title || 'Alert';
    let confirmLabel,
      cancelLabel,
      content;

    const buttons = [];

    if (type === 'yesNo') {
      buttons.push();
      confirmLabel = 'Yes';
      cancelLabel = 'No';
      content = (
        <div className={styles.alert}>
          <span className={styles.icon} key="icon">
            <Help sx={{ fontSize: '48px' }} />
          </span>
          <span className={styles.text} key="text">
            {text}
          </span>
        </div>
      );
    } else if (type === 'okCancel') {
      confirmLabel = 'OK';
      cancelLabel = 'Cancel';
    } else if (type === 'ok') {
      confirmLabel = 'OK';
    } else if (type === 'close') {
      confirmLabel = 'Close';
    }

    const ref = React.createRef();

    const doDismiss = (reason) => {
      ref.current.props.onClose(reason.toLowerCase());
    };

    if (!confirmLabel) {
      confirmLabel = 'Dismiss';
    }

    const keyMap = {
      OK: 'Enter',
      Cancel: 'Escape'
    };
    const handlers = {
      OK: () => doDismiss(confirmLabel),
      Cancel: () =>  doDismiss(cancelLabel ? cancelLabel : confirmLabel)
    };

    buttons.push(<Button variant="outlined" onClick={() => doDismiss(confirmLabel)} key="OK">{confirmLabel}</Button>);
    if (cancelLabel) {
      buttons.push(<Button variant="outlined" color="secondary" onClick={() => doDismiss(cancelLabel)} key="cancel">{cancelLabel}</Button>);
    }

    content = content || (
      <div className={styles.alert}>
        <span className={styles.icon} key="icon">
          <ReportProblem sx={{ fontSize: '48px' }} />
        </span>
        <span className={styles.text} key="text">
          {text}
        </span>
      </div>
    );



    document.addEventListener('keydown', (e) => { console.log(e.code); });

    return new Promise((resolve, reject) => {
      DialogController.doModal(
        <ManagedDialog
          ref={ref}
          onClose={(reason) => {
            restoreFocus();
            if (reason === cancelLabel) {
              reject();
            } else {
              resolve(reason);
            }
          }}
        >
          <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
              {content}
            </DialogContent>
            <DialogActions>
              {buttons}
            </DialogActions>
          </GlobalHotKeys >
        </ManagedDialog>
      );
    });
  }


  /**
   * Renders a simple text input field with a title and some text
   *
   * @param {String} text text content to show in the dialog
   * @param {String?} title dialog title
   * @param {String='okCancel'} type dialog type oneOf(['okCancel' (default), 'ok','close'])
   *  okCancel shows 'OK' and  'Cancel' buttons
   *  ok just shows a single 'OK' button
   *  close shows a 'Close' button
   * @returns {Promise} a promise which is resolved with the value entered or rejected if the dialog was canceled
   */
  static doPrompt(text, title, value = '', type = 'okCancel') {
    const activeElement = document.activeElement;
    const restoreFocus = () => {
      if (activeElement) {
        activeElement.focus();
      }
    };

    let confirmLabel,
      cancelLabel;

    if (type === 'okCancel') {
      confirmLabel = 'OK';
      cancelLabel = 'Cancel';
    } else if (type === 'ok') {
      confirmLabel = 'OK';
    } else if (type === 'close') {
      confirmLabel = 'Close';
    }

    return new Promise((resolve, reject) => {
      DialogController.doModal(
        <PromptDialog
          dialogTitle={title}
          confirmLabel={confirmLabel}
          cancelLabel={cancelLabel}
          text={text}
          value={value}
          onClose={(reason, val) => {
            restoreFocus();
            if (reason === cancelLabel) {
              reject();
            } else {
              resolve(val);
            }
          }}
        />
      );
    });
  }

}
