import React, { Component } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

import RingLoader from 'react-spinners/RingLoader';
import Header from '../Header';
import Footer from '../Footer';

import {
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  Grid,
  Checkbox,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

import { hasVotedForImage, getFilteredImages } from '../../model/selectors';
import * as actions from '../../model/actions';

import styles from './ImageCarouselView.module.scss';

class ImageCarouselView extends Component {

  constructor(props) {
    super(props);

    this.swiperRef = React.createRef();

    this.state = {
      index: 0,
    };

    this.renderImageCard = this.renderImageCard.bind(this);
    this.renderSwiperImageCard = this.renderSwiperImageCard.bind(this);
    this.handleVoteForImage = this.handleVoteForImage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleVoteForCurrentImage = this.handleVoteForCurrentImage.bind(this);
    this.handleNextSlide = this.handleNextSlide.bind(this);
    this.handlePrevSlide = this.handlePrevSlide.bind(this);

    this.handleVoteForCurrentSwiperImage = this.handleVoteForCurrentSwiperImage.bind(this);

    this.keyMap = {
      Vote: 'Space',
    };

    this.handlers = {
      Vote: this.handleVoteForCurrentImage,
    };

    this.swiperKeyMap = {
      Vote: 'Space',
      Next: 'ArrowRight',
      Prev: 'ArrowLeft'
    };

    this.swiperHandlers = {
      Vote: this.handleVoteForCurrentSwiperImage,
      Next: this.handleNextSlide,
      Prev: this.handlePrevSlide
    };
  }

  handleVoteForImage(image) {
    if (this.props.hasVotedForImage(image)) {
      this.props.downVoteImage(image);
    } else {
      this.props.upVoteImage(image);
    }
  }

  handleChange(index) {
    this.setState({
      index
    });
  }

  handleVoteForCurrentImage(event) {
    const image = this.filteredImages[this.state.index];
    this.handleVoteForImage(image);
    event.stopPropagation();
  }

  handleVoteForCurrentSwiperImage() {
    const index = this.swiperRef.current.swiper.activeIndex;
    const image = this.filteredImages[index];
    this.handleVoteForImage(image);
  }

  handleActiveIndexChanged(event) {
    this.setState({
      index: event.activeIndex
    });
  }

  /* eslint-disable no-debugger */
  renderImageCard(image, index) {
    // const checked = this.props.hasVotedForImage(image);
    const content = (
      <Grid item xs={4} key="content" className={styles.ContentGrid}>
        <CardContent className={styles.Content}>
          <CardActions className={styles.Actions}>

            <Checkbox className={styles.Checkbox} checked={this.props.hasVotedForImage(image)} onChange={() => { this.handleVoteForImage(image); }} />
            <Typography variant="body2" component="span">
              Vote for this item
            </Typography>
          </CardActions>
        </CardContent>
      </Grid>
    );

    const media = (
      <Grid item xs={4} key={image.id} className={styles.MediaGrid}>
         <div className={styles.identificationBug}>
            <div className={styles.pretext}>
              <Typography variant="h6" component="span" sx={{ textAlign: "left" }}>
                {image.category}
              </Typography>
            </div>
            <div className={styles.smallText}>
              <Typography variant="h6" component="span" sx={{ textAlign: "left" }}>
                {image.category.slice(0, 1)}
              </Typography>
            </div>
        </div>
        <CardMedia
          className={styles.Media}
          image={image.url}
        >
        </CardMedia>
      </Grid>
    );


    return (
      <Card raised className={styles.Banner} key={`image-${index}`}>
        <Grid container spacing={0} className={styles.BannerGrid}>
          {[media, content]}
        </Grid>
      </Card>
    );
  }

  handleNextSlide() {
    this.swiperRef.current.swiper.slideNext(300);
  }
  handlePrevSlide() {
    this.swiperRef.current.swiper.slidePrev(300);
  }

  renderSwiperImageCard(image, index) {
    return (
      <SwiperSlide key={index}>
        {this.renderImageCard(image, index)}
      </SwiperSlide>
    );
  }


  get filteredImages() {
    return this.props.getFilteredImages();
  }


  renderLonelyContent() {
    return (
      <div className={styles.Catalog}>
        <div className={clsx(styles.helpText, styles.lonely)}>
          <SearchIcon fontSize="large" />
          <Typography variant="h6" color="inherit" component="div">
            Move along... Nothing to see here...
          </Typography>
        </div>
      </div>
    );
  }

  renderNoResultsContent() {
    return (
      <div className={styles.Swiper}>
        <div className={clsx(styles.helpText, styles.lonely)}>
          <SearchIcon fontSize="large" />
          <Typography variant="h6" color="inherit" component="div">
            Move along... Nothing to see here...
          </Typography>
        </div>
      </div>
    );
  }

  renderSwiper() {
    return (
      <>
        <GlobalHotKeys keyMap={this.swiperKeyMap} handlers={this.swiperHandlers}>
          <Swiper
            pagination={false}
            navigation={true}
            modules={[Pagination, Navigation]}
            className={styles.Swiper}
            ref={this.swiperRef}
          >
            {
              this.filteredImages.map(this.renderSwiperImageCard)
            }
          </Swiper>
        </GlobalHotKeys>
      </>
    );
  }

  render() {
    let content;
    if (this.props.initializing) {
      content = (
        <div className={clsx(styles.Swiper, styles.loader)}>
          <RingLoader className={styles.loader} color="white" />
        </div>
      );
    } else if (this.props.images.length === 0) {
      content = this.renderLonelyContent();
    } else if (this.filteredImages.length === 0 && this.props.filter !== '') {
      content = this.renderNoResultsContent();
    } else {
      content = this.renderSwiper();
    }
    return (
      <GlobalHotKeys keyMap={this.keyMap} handlers={this.handlers}>
        <div className={styles.Carousel}>
          <Header/>
            {content}
          <Footer/>
        </div>
      </GlobalHotKeys>
    );
  }
}


ImageCarouselView.propTypes = {
  filter: PropTypes.string,
  images: PropTypes.array,
  initializing: PropTypes.bool.isRequired,
  campaign: PropTypes.object.isRequired,
  hasVotedForImage: PropTypes.func,
  getFilteredImages: PropTypes.func,
  upVoteImage: PropTypes.func,
  downVoteImage: PropTypes.func,

};

ImageCarouselView.defaultProps = {
  filter: '',
  images: [],
  campaign: {},
  initializing: true,
  hasVotedForImage: () => { },
  getFilteredImages: () => { },
  upVoteImage: () => { },
  downVoteImage: () => { },
};

ImageCarouselView.connector = (state) => {
  const currentCampaign = state.appState.config.currentCampaign;
  const campaign = state.appState.config.campaigns[currentCampaign];
  const initializing = !state.appState.ready;

  return {
    images: Object.values(state.images),
    campaign,
    initializing,
    hasVotedForImage: (image) => {
      return hasVotedForImage(state, image);
    },
    getFilteredImages: () => {
      return getFilteredImages(state);
    }
  };
};

ImageCarouselView.commander = (dispatch) => {
  return {
    upVoteImage: (image) => dispatch({ type: actions.RECORD_VOTE, payload: { image } }),
    downVoteImage: (image) => dispatch({ type: actions.REMOVE_VOTE, payload: { image } }),
  };
};


export default connect(
  ImageCarouselView.connector,
  ImageCarouselView.commander
)(ImageCarouselView);
