import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';

import LengthyOperationDialog from '../../Dialogs/LengthyOperationDialog';
import ImageCatalogView from '../ImageCatalogView';
import ImageCarouselView from '../ImageCarouselView';
import WinnersShowcaseView from '../WinnersShowcaseView';
import LeaderboardView from '../LeaderboardView';
import GalleryCatalogView from '../GalleryCatalogView';
import ComingSoon from '../ComingSoon';

import AppController from '../../Controllers/AppController';

import styles from './Workspace.module.scss';

class Workspace extends Component {
  constructor(props) {
    super(props);
    this.history = createHistory();
    AppController.history = this.history;
  }

  render() {
    const extraClasses = this.history.location && this.history.location.pathname ? this.history.location.pathname.split('/').map(path => styles[path]).filter(x => x) : [];
    const classes = [styles.workspace, ...extraClasses];
    const className = clsx.apply(null,  classes);
    return (
      <div className={className}>
          <Router history={this.history}>
            <Switch>
              <Route exact path="/">
                <Redirect to="/vote/current" replace={true} />
              </Route>
              <Route exact path="/vote">
                <Redirect to="/vote/current" replace={true} />
              </Route>
              <Route path="/vote/:id" component={this.props.view === 'carousel' ? ImageCarouselView : ImageCatalogView} />
              <Route exact path="/embed/current" replace={true} />
              <Route exact path="/embed/">
                <Redirect to="/embed/current" replace={true} />
              </Route>
              <Route exact path="/embed/:campaignId" component={WinnersShowcaseView} />
              <Route exact path="/gallery">
                <Redirect to="/gallery/current" replace={true} />
              </Route>
              <Route path="/gallery/:campaignId" component={WinnersShowcaseView} />
              <Route path="/directory" component={GalleryCatalogView} />
              <Route path="/standings" component={LeaderboardView} />
              <Route path="/coming" component={ComingSoon} />
              <Route>
                <Redirect to="/" replace={true} />
              </Route>
            </Switch>
          </Router>
        <LengthyOperationDialog />
      </div>
    );
  }
}

Workspace.propTypes = {
  view: PropTypes.string,
};

Workspace.defaultProps = {
  view: 'carousel',
};

Workspace.connector = (state) => {
  return {
    view: state.appState.view,
  };
};

export default connect(
  Workspace.connector
)(Workspace);
