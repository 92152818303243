import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import Logo from '../../Components/Logo';
import styles from './Header.module.scss';
import AppController from '../../Controllers/AppController';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Hurricane Voting System',
    };
  }

  componentDidMount() {
    this.recomputeTitleByLocation(AppController.history.location.pathname);
    AppController.history.listen((url) => {
      this.recomputeTitleByLocation(url.pathname);
    });
  }

  componentDidUpdate(prevProps) {
      if (this.props.campaign !== prevProps.campaign) {
        this.recomputeTitleByLocation(AppController.history.location.pathname);
      }
  }

  recomputeTitleByLocation(location) {
    let title;
    const pathname = location || "";

    if (pathname.startsWith('/standings/')) {
      const [,,year] = location.split('/');
      title = `${year} Standings`;
    } else if (pathname.startsWith('/directory/')) {
      const [,,year] = location.split('/');
      title = `${year} Directory of Competitions`;
    } else if (pathname.startsWith('/coming')) {
      title = 'Image Voting System';
    } else if (!this.props.campaign) {
      title = 'Image Voting System';
    } else {
      title = this.props.campaign.title || this.props.campaign.name;
    }
    this.setState({
      title
    });
  }


  render() {
    return (
      <AppBar position="static">
        <Toolbar variant="dense" style={{ backgroundColor: 'black' }}>
          <Typography variant="h6" color="inherit" component="div" style={{
            maxWidth: 'calc(100% - 60px',
            textAlign: 'left',
            paddingTop: '5px',
            paddingBottom: '5px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}>
            {this.state.title}
          </Typography>
          <a className={styles.logo} href="https://darkroomers.com" target="_blank" rel="noreferrer" >
            <Logo variant="dark" className={styles.logoIcon} />
            <Logo variant="chip" className={styles.chipIcon} />
          </a>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  campaign: PropTypes.object,
};

Header.defaultProps = {
  campaign: null,
};

Header.connector = (state) => {
  const campaignId = state.appState.config.currentCampaign;
  return {
    campaign: state.appState.config.campaigns[campaignId],
  };
};

Header.commander = (dispatch) => {
  return {
  };
};

export default connect(
  Header.connector,
  Header.commander
)(Header);
