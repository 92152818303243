import { isTodayWithinDateRange, isAfterDate } from '../Utils/DateUtils';

export const isVotingEnabled = (campaign) => campaign && campaign.votingStartDate && campaign.votingEndDate;

export const isVotingOpen = (campaign) => {
  return isVotingEnabled(campaign) &&
    isTodayWithinDateRange(campaign.votingStartDate, campaign.votingEndDate);
};

export const hasVotingEnded = (campaign) => {
  return isVotingEnabled(campaign) &&
    isAfterDate(campaign.votingEndDate);
};
