import Immutable from 'immutable';
import * as actions from '../actions';

const BASE_STATE = {};

const DEFAULT_STATE = Immutable.fromJS(BASE_STATE);

function initializeBallot(state, ballot) {
  return Immutable.fromJS(ballot);
}

export function hasVotedForImageByID(state, id) {
  const ballot = state.getIn(['vote', 'ballot']);
  return ballot ? ballot.some(campaign => campaign.some(cat => cat.find(image => image === id))) : false;
}

export function getVoteCountForCategoryByCampaign(state, category, campaign) {
  const path = ['vote', 'ballot', campaign, category];
  const votes = state.getIn(path);
  return votes ? votes.size : 0;
}

function recordVote(state, image) {
  const { id, category, campaign } = image;
  if (!id) {
    throw new Error(`Malformed Image ${image}`);
  }

  if (hasVotedForImageByID(state, id)) {
    throw new Error(`Can only vote for image once ${id}`);
  }

  const path = ['vote', 'ballot', campaign, category];

  let votes = state.getIn(path) || Immutable.fromJS([]);

  votes = votes.push(id);

  if (votes.length > 3) {
    throw new Error(`Too many votes`);
  }

  const result = state.setIn(path, votes);

  return result;
}

function removeVote(state, image) {
  const { id, category, campaign } = image;
  if (!id) {
    throw new Error(`Malformed Image ${image}`);
  }

  const path = ['vote', 'ballot', campaign, category];

  const votes = state.getIn(path).filter(imageId => imageId !== id);
  return state.setIn(path, Immutable.fromJS(votes));
}


export default function images(state = DEFAULT_STATE, action) {
  switch (action.type) {

    case actions.INIT_BALLOT:
      return initializeBallot(state, action.payload.ballot);

    case actions.RESET_BALLOT:
      return initializeBallot(state, BASE_STATE);

    case actions.RECORD_VOTE:
      return recordVote(state, action.payload.image);

    case actions.REMOVE_VOTE:
      return removeVote(state, action.payload.image);

    default:
      break;
  }

  return state;
}
