import EventEmitter from 'eventemitter3';

import constants, { MAKE_API } from "../constants";
import HttpError, { AxiosError } from "../Utils/HttpError";

class Backend extends EventEmitter {
  constructor() {
    super();

    this.healthEndpoint = MAKE_API('health');
    this.configEndpoint = MAKE_API('config');
    this.voteEndpoint = MAKE_API('votes');

    this.fetchHealth = this.fetchHealth.bind(this);
    this.fetchConfiguration = this.fetchConfiguration.bind(this);
    this.fetchImages = this.fetchImages.bind(this);
    this.fetchBallot = this.fetchBallot.bind(this);
    this.recordBallot = this.recordBallot.bind(this);
    this.fetchStandings = this.fetchStandings.bind(this);
    this.fetchDirectory = this.fetchDirectory.bind(this);

    this.fingerprint = constants.DEFAULT_FINGERPRINT;
  }

  setFingerprint(fingerprint) {
    this.fingerprint = fingerprint;
  }

  async fetchImages(campaign) {
    try {
      this.emit(this.statusEvent, this.status.fetchingImages);

      const response = await fetch(MAKE_API('images', campaign));

      if (!response.ok) {
        await this.throwAxiosError(response);
      }

      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async fetchBallot() {
    try {
      this.emit(this.statusEvent, this.status.fetchingBallot);

      const response = await fetch(MAKE_API('votes', this.fingerprint.visitorId));

      if (!response.ok) {
        await this.throwAxiosError(response);
      }

      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async fetchStandings(year) {
    try {
      this.emit(this.statusEvent, this.status.fetchingStandings);

      const response = await fetch(MAKE_API('standings', year));

      if (!response.ok) {
        await this.throwAxiosError(response);
      }

      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async fetchDirectory(year) {
    try {
      this.emit(this.statusEvent, this.status.fetchingDirectory);

      const response = await fetch(MAKE_API('directory', year));

      if (!response.ok) {
        await this.throwAxiosError(response);
      }

      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async recordBallot(ballot) {

    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(ballot),
    };
    try {
      this.emit(this.statusEvent, this.status.recordingBallot);

      const response = await fetch(MAKE_API('vote', this.fingerprint.visitorId), options);

      if (!response.ok) {
        await this.throwAxiosError(response);
      }

      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async throwAxiosError(response) {
    return new Promise(async (resolve, reject) => {
      if (response.headers
          && response.headers.get('Content-Type')
          && response.headers.get('Content-Type').split(';').shift() === 'application/json') {
        reject(new AxiosError(response, await response.json()));
      } else {
        reject(new HttpError(response.status, response.statusText));
      }
    });
  }

  async fetchHealth() {
    const options = {
      headers: {
        ...this.commonHeaders,
      },
    };
    const response = await fetch(this.healthEndpoint, options);
    if (!response.ok) {
      await this.throwAxiosError(response);
    }
    const health = await response.json();
    return health;
  }

  async fetchConfiguration() {
    try {
      this.emit(this.statusEvent, this.status.fetchingConfig);
      const response = await fetch(this.configEndpoint);
      if (!response.ok) {
        await this.throwAxiosError(response);
      }
      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }
}

Backend.prototype.statusEvent = 'status';

Backend.prototype.status = {
    idle: 'idle',
    fetchingBallot: 'Fetching Ballot',
    fetchingImages: 'Fetching Images',
    fetchingStandings: 'Fetching Standings',
    fetchingDirectory: 'Fetching Directory',
    recordingBallot: 'Recording Ballot',
    fetchingConfig: 'Fetching Config',
};

const backend = new Backend();

export default backend;
