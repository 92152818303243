import { combineReducers } from 'redux';

import appState from './app-state';
import images from './images';
import ballot from './ballot';
import standings from './standings';
import directory from './directory';

export default combineReducers({
  appState,
  images,
  ballot,
  standings,
  directory,
});
