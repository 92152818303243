import React, { Component } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import "swiper/css/bundle";

import store from './model/store';
import * as actions from './model/actions';

import Workspace from './Chrome/Workspace';
import backend from './model/backend';

import AppController from './Controllers/AppController';

class App extends Component {
  constructor(props) {
    super(props);

    AppController.store = store;
    FingerprintJS.load()
      .then(fp => fp.get())
      .then((fingerprint) => {
        backend.setFingerprint(fingerprint);
        store.dispatch({ type: actions.INITIALIZE });
      });
  }

  componentDidMount() {
    window.setTimeout(() => {
      document.querySelector('body').scroll(0, 1);
    });
  }

  render() {
    return (
      <ReduxProvider store={store}>
        <Workspace />
      </ReduxProvider>
    );
  }
}

export default App;
