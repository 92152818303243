import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import GridViewIcon from '@mui/icons-material/GridViewOutlined';
import CarouselViewIcon from '@mui/icons-material/ViewCarouselOutlined';
import Grid3x3ViewIcon from '@mui/icons-material/Grid3x3Outlined';
import Grid4x4ViewIcon from '@mui/icons-material/Grid4x4Outlined';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import PrintIcon from '@mui/icons-material/Print';

import AppController from '../../Controllers/AppController';

import { VIEW_CATEGORIES } from '../../model/reducers/app-state';

import * as actions from '../../model/actions';

import styles from './Footer.module.scss';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showViewSelector: false,
      showGridExpandSelector: false,
      showCategorySelector: false,
      showDocumentGallerySelector: false,
    };
    this.handleCategoryChanged = this.handleCategoryChanged.bind(this);
  }

  handleCategoryChanged(e) {
    this.props.switchToCategory(e.target.value);
  }

  componentDidMount() {
    this.recomputeViewStateByLocation(AppController.history.location.pathname);
    AppController.history.listen((url) => {
      this.recomputeViewStateByLocation(url.pathname);
    });
  }

  recomputeViewStateByLocation(location) {
    const pathname = location || "";
    this.setState({
      showViewSelector: pathname.startsWith('/vote/'),
      showCategorySelector: !pathname.startsWith('/standings/') && !pathname.startsWith('/coming'),
      showGridExpandSelector: pathname.startsWith('/standings/'),
      showDocumentGallerySelector: pathname.startsWith('/gallery/'),
    });
  }

  render() {
    const isGalleryView = AppController.history.location.pathname.startsWith('/gallery/');

    const categories = [
      ...this.props.campaign.categories.map((category) => {
        return (<MenuItem key={category} value={category}>{category}</MenuItem>);
      }),
      ...Object.entries(VIEW_CATEGORIES)
        .filter(([category]) => {
          if (isGalleryView) {
            return category !== 'voted';
          }
          return true;
        })
        .map(([category, label]) => {
          return (<MenuItem key={category} value={category}>{label}</MenuItem>);
        }),
    ];

    return (
      <AppBar position="static" color="primary" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar style={{ backgroundColor: 'black' }}>
          {this.props.onPrint && <div className={styles.actions}>
              <IconButton variant="outlined" onClick={this.props.onPrint}>
                <PrintIcon />
              </IconButton >
          </div>}
          {this.state.showCategorySelector && <div className={styles.categorySelector}>
            <Select
              label="Category"
              sx={{
                color: 'white'
              }}
              variant="standard"
              value={this.props.category}
              onChange={this.handleCategoryChanged}
            >
              {categories}
            </Select>
          </div>}
          {this.state.showViewSelector && <div className={styles.viewSelector}>
            <ToggleButtonGroup orientation="horizontal" value={this.props.view} exclusive onChange={(e) => this.props.switchToView(e.currentTarget.value)}>
              <ToggleButton value="grid" aria-label="grid">
                <GridViewIcon />
              </ToggleButton>
              <ToggleButton value="carousel" aria-label="carousel">
                <CarouselViewIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>}
          {this.state.showDocumentGallerySelector && <div className={styles.viewSelector}>
            <ToggleButtonGroup orientation="horizontal" value={this.props.view} exclusive onChange={(e) => this.props.switchToView(e.currentTarget.value)}>
              <ToggleButton value="graded" aria-label="graded">
                <GradingOutlinedIcon />
              </ToggleButton>
              <ToggleButton value="carousel" aria-label="carousel">
                <CarouselViewIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>}
          {this.state.showGridExpandSelector && <div className={styles.viewSelector}>
            <ToggleButtonGroup orientation="horizontal" value={this.props.expanded} exclusive onChange={(e) => this.props.expandContent(e.currentTarget.value)}>
              <ToggleButton value="condensed" aria-label="condensed">
                <Grid3x3ViewIcon />
              </ToggleButton>
              <ToggleButton value="expanded" aria-label="expanded">
                <Grid4x4ViewIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>}
        </Toolbar>
      </AppBar>
    );
  }
}

Footer.propTypes = {
  campaign: PropTypes.object,
  view: PropTypes.string,
  category: PropTypes.string,
  switchToCategory: PropTypes.func,
  switchToView: PropTypes.func,
  expandContent: PropTypes.func,
  expanded: PropTypes.string,
  onPrint: PropTypes.func,
};

Footer.defaultProps = {
  category: '',
  campaign: { categories: [] },
  view: 'grid',
  switchToCategory: () => { },
  switchToView: () => { },
  expandContent: () => { },
  onPrint: null,
};

Footer.connector = (state) => {
  return {
    campaign: state.appState.config.campaigns[state.appState.config.currentCampaign],
    category: state.appState.category,
    view: state.appState.view,
    expanded: state.appState.expanded,
  };
};

Footer.commander = (dispatch) => {
  return {
    switchToCategory: (category) => dispatch({ type: actions.SET_ACTIVE_CATEGORY, payload: { category } }),
    switchToView: (view) => dispatch({ type: actions.SET_ACTIVE_VIEW, payload: { view } }),
    expandContent: (expanded) => dispatch({ type: actions.SET_EXPANDED_STATE, payload: { expanded } }),
  };
};

export default connect(
  Footer.connector,
  Footer.commander
)(Footer);
