import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RingLoader from 'react-spinners/RingLoader';
// import ReactDOM from 'react-dom';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';

import styles from './GalleryCatalogView.module.scss';
import * as actions from '../../model/actions';

import Header from '../Header';
import Footer from '../Footer';

class GalleryCatalogView extends Component {
  constructor(props) {
    super(props);

    this.renderCampaignThumbnailCard = this.renderCampaignThumbnailCard.bind(this);
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);

    this.state = {
      openSnackbar: false,
      snackbarMessage: null,
      snackbarSeverity: 'info'
    };
  }

  handleCloseSnackbar() {
    this.setState({ openSnackbar: false });
  }

  renderCampaignThumbnailCard(campaign, index) {
    return (
      <Grid sx={{
        maxWidth: '345px',
      }}
        item
        key={`image-${index}`}
        xs={6}
      >
        <Card sx={{
          maxWidth: 345,
          minWidth: 300,
          maxHeight: 300,
          minHeight: 300,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'dodgerblue',
            '*': {
              color: 'white',
              borderColor: 'white',
            },
          }
        }} onClick={(e) => {
          this.props.openGallery(campaign.id);
        }}>
          <CardMedia
            component="img"
            alt={campaign.name}
            height="200"
            image={campaign.thumbnail}
          />
          <div className={styles.cardContent}>
            <Typography variant="body1">
              {campaign.galleryInfo}
            </Typography>
          </div>
        </Card>
      </Grid>
    );
  }

  renderImageGrid() {
    const cards = this.props.galleryCampaigns.map(this.renderCampaignThumbnailCard);

    return (
      <div className={clsx(styles.imageCatalogView, styles.Catalog)}>
        <Box sx={{
          position: 'relative',
          width: '100%',
          overflow: 'auto',
          height: `calc(var(--vph, 100vh) - 63px)`,
        }}>
          <Grid sx={{
            marginLeft: '10px',
            marginTop: 0,
            width: 'calc(100% - 16px)',
            paddingBottom: '100px',
            '@media(max-width: 786px)': {
              flexDirection: 'column',
            },
            '& .MuiGrid-item': {
              maxWidth: '375px',
              '@media(max-width: 786px)': {
                paddingLeft: 0,
                paddingRight: 0,
                marginLeft: 'auto',
                marginRight: 'auto',
              }
            }
          }}
            container
            rowSpacing={4}
            columnSpacing={8}
          >
            {cards}
          </Grid>
        </Box>
        </div>
    );
  }

  renderNoResultsContent() {
    return (
      <div className={styles.Catalog}>
        <div className={clsx(styles.helpText, styles.lonely)}>
          <SearchIcon fontSize="large" />
          <Typography variant="h6" color="inherit" component="div">
            Sadly, We have nothing to show yet...
          </Typography>
        </div>
      </div>
    );
  }

  render() {
    let content;
    if (this.props.initializing) {
      content = (
        <div className={styles.Catalog}>
          <div  className={styles.loader}>
            <RingLoader className={styles.spinner} color="white" />
          </div>
        </div>
      );
    } else if (this.props.galleryCampaigns.length === 0) {
      content = this.renderNoResultsContent();
    } else {
      content = this.renderImageGrid();
    }

    return (
      <>
        <Header />
          {content}
          <Snackbar open={this.state.openSnackbar} autoHideDuration={6000} onClose={this.handleCloseSnackbar}>
            <Alert onClose={this.handleCloseSnackbar} severity={this.state.snackbarSeverity} sx={{ width: '100%' }}>
              {this.state.snackbarMessage}
            </Alert>
          </Snackbar>
        <Footer />
      </>
    );
  }
}

GalleryCatalogView.propTypes = {
  galleryCampaigns: PropTypes.array,
  initializing: PropTypes.bool,
  openGallery: PropTypes.func,
};

GalleryCatalogView.defaultProps = {
  galleryCampaigns: [],
  initializing: true,
  openGallery: () => {},
};

GalleryCatalogView.connector = (state) => {
  const galleryCampaigns = Object.values(state.directory);
  const initializing = !state.appState.ready;

  return {
    galleryCampaigns,
    initializing,
  };
};

GalleryCatalogView.commander = (dispatch) => {
  return {
    openGallery: (id) => dispatch({ type: actions.NAVIGATE, payload: { location: `/gallery/${id}`, method: 'push' } }),
  };
};


export default connect(
  GalleryCatalogView.connector,
  GalleryCatalogView.commander
)(GalleryCatalogView);
