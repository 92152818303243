import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RingLoader from 'react-spinners/RingLoader';
import { GlobalHotKeys } from 'react-hotkeys';

import { DataGrid } from '@mui/x-data-grid';

import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';

import lodash from 'lodash';

import { openGridPrintView } from "../../Utils/ReportUtils";
import AppController from '../../Controllers/AppController';

import Header from '../Header';
import Footer from '../Footer';

import styles from './LeaderboardView.module.scss';

class LeaderBoardView extends Component {

  get pointsColumns() {
    return this.props.standings.reduce((acc, standing) => {
      const campaign = this.props.campaigns[standing.campaign];
      campaign.categories.forEach((category) => {
        if (!acc.includes(category)) {
          acc.push(category);
        }
      });
      return acc;
    }, []).map((category) => {
      const categoryName = lodash.startCase(category);
      return {
        property: `points${categoryName}`,
        name: `Points ${categoryName}`,
      };
    });
  }

  get columns() {
    return [
      { field: 'name', headerName: 'Name', width: 330 },
      ...this.pointsColumns.map((column) => {
        return {
          field: column.property,
          headerName: column.name,
          width: 190,
          type: 'number',
        };
      })
    ];
  }

  get expandedColumns() {
    const renderCell = (params) => {
      return lodash.isNumber(params.value) ? params.value : 0;
    };
    const renderValue = (fieldDef) => {
      return lodash.isNumber(fieldDef.value) ? fieldDef.value : 0;
    };
    const columns = this.pointsColumns;
    const expanded = this.props.standings.reduce((acc, standing) => {
      const name = standing.campaign.split('-').pop();
      columns.forEach((column) => {
        acc.push({
          field: `${standing.campaign}-${column.property}`,
          headerName: `${name} ${column.name}`,
          width: 190,
          type: 'number',
          valueFormatter: renderValue,
          renderCell: renderCell,
        });
      });
      return acc;
    }, []);
    return [
      { field: 'name', headerName: 'Name', width: 330 },
      ...expanded,
      ...this.pointsColumns.map((column) => {
        return {
          field: column.property,
          headerName: column.name,
          width: 190,
          type: 'number',
        };
      })
    ];
  }

  get data() {
    const columns = this.pointsColumns.map((column) => {
      return column.property;
    });

    const data = this.props.standings.reduce((acc, standing) => {
      standing.condensed.forEach((entry) => {
        if (!acc[entry.userId]) {
          acc[entry.userId] = {
            id: entry.userId,
            name: entry.name,
          };
        }

        columns.forEach((column) => {
          const path = `${entry.userId}.${column}`;
          lodash.set(acc, path, lodash.get(acc, path, 0) +
            lodash.get(entry, column, 0));
        });
      });
      return acc;
    }, {});
    return data;
  }

  get rows() {
    return Object.values(this.data);
  }

  get expandedRows() {
    const columns = this.pointsColumns.map((column) => {
      return column.property;
    });

    const data = this.data;

    this.props.standings.forEach((standing) => {
      standing.condensed.forEach((entry) => {
        columns.forEach((column) => {
          const path = `${entry.userId}.${standing.campaign}-${column}`;
          lodash.set(data, path, lodash.get(entry, column, 0));
        });
      });
    });

    return Object.values(data);
  }

  renderLeaderBoard() {
    const rows = this.props.expanded ? this.expandedRows : this.rows;
    const columns = this.props.expanded ? this.expandedColumns : this.columns;

    const handlePrint = () => {
      openGridPrintView(
        this.props.title,
        columns,
        rows,
      );
    };

    const scoreSheetKeyMap = {
      Print: 'command+p',
    };

    const scoreSheetHandlers = {
      Print: handlePrint.bind(this)
    };

    return (
      <GlobalHotKeys keyMap={scoreSheetKeyMap} handlers={scoreSheetHandlers}>
        <div className={styles.Showcase}>
          <Header/>
            <DataGrid
              sx={{
                backgroundColor: 'white'
              }}
              rows={rows}
              columns={columns}
              autoPageSize
            />
          <Footer onPrint={handlePrint}/>
        </div>
      </GlobalHotKeys>
    );
  }

  renderLonelyContent() {
    return (
      <div className={styles.Showcase}>
        <Header/>
          <div className={styles.Showcase}>
            <div className={clsx(styles.helpText, styles.lonely)}>
              <SearchIcon fontSize="large" />
              <Typography variant="h6" color="inherit" component="div">
                Move along... Nothing to see here...
              </Typography>
            </div>
          </div>
        <Footer/>
      </div>
    );
  }

  renderNoResultsContent() {
    return (
      <div className={clsx(styles.helpText, styles.lonely)}>
        <Header/>
        <SearchIcon fontSize="large" />
        <Typography variant="h6" color="inherit" component="div">
          Move along... Nothing to see here...
        </Typography>
        <Footer/>
      </div>
    );
  }

  render() {
    let content;
    if (this.props.initializing) {
      content = (
        <div className={styles.Showcase}>
            <Header/>
              <div className={styles.loader}>
                  <RingLoader className={styles.loader} color="white" />
              </div>
          <Footer/>
        </div>
      );
    } else if (this.props.standings.length === 0) {
      content = this.renderLonelyContent();
    } else {
      content = this.renderLeaderBoard();
    }

    return content;
  }
}

LeaderBoardView.propTypes = {
  campaigns: PropTypes.object,
  standings: PropTypes.array,
  initializing: PropTypes.bool.isRequired,
  expanded: PropTypes.bool,
  title: PropTypes.string,
};

LeaderBoardView.defaultProps = {
  standings: [],
  campaigns: {},
  initializing: true,
  expanded: false,
  title: 'Current Standings',
};

LeaderBoardView.connector = (state) => {
  const initializing = !state.appState.ready;
  const pathname = AppController.history.location.pathname;
  const [,,year] = pathname.split('/');
  const title = `${year} Standings`;

  return {
    standings: state.standings,
    campaigns: state.appState.config.campaigns,
    expanded: state.appState.expanded === 'expanded',
    initializing,
    title,
  };
};

LeaderBoardView.commander = (dispatch) => {
  return {
  };
};


export default connect(
  LeaderBoardView.connector,
  LeaderBoardView.commander
)(LeaderBoardView);
