import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { GlobalHotKeys } from 'react-hotkeys';
import RingLoader from 'react-spinners/RingLoader';
import { DataGrid } from '@mui/x-data-grid';
import { openGridPrintView } from "../../Utils/ReportUtils";
import {
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  Grid,
  Box,
  Button
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import { getFilteredImages } from '../../model/selectors';

import Header from '../Header';
import Footer from '../Footer';
import * as actions from '../../model/actions';
import styles from './WinnersShowcaseView.module.scss';

class WinnersShowcaseView extends Component {

  constructor(props) {
    super(props);

    this.swiperRef = React.createRef();

    this.handleNextSlide = this.handleNextSlide.bind(this);
    this.handlePrevSlide = this.handlePrevSlide.bind(this);

    this.renderImageCard = this.renderImageCard.bind(this);
    this.renderSwiperImageCard = this.renderSwiperImageCard.bind(this);

    this.keyMap = {
      Next: 'ArrowRight',
      Prev: 'ArrowLeft'
    };

    this.handlers = {
      Next: this.handleNextSlide,
      Prev: this.handlePrevSlide,
    };

  }

  renderImageCard(image, index) {
    const content = (
      <Grid item xs={4} key="content" className={styles.ContentGrid}>
        <CardContent className={styles.Content}>
          <CardActions className={styles.Actions}>
            <Typography variant="body2" component="span">
              {image.title} {image.place} by {image.user.name}
            </Typography>
          </CardActions>
        </CardContent>
      </Grid>
    );

    const media = (
      <Grid item xs={4} key={image.id} className={styles.MediaGrid}>
        <CardMedia
          className={styles.Media}
          image={image.url}
        >
        </CardMedia>
      </Grid>
    );

    return (
      <Card raised className={styles.Banner} key={`image-${index}`}>
        <Grid container spacing={0} className={styles.BannerGrid}>
          {[media, content]}
        </Grid>
      </Card>
    );
  }

  renderSwiperImageCard(image, index) {
    return (
      <SwiperSlide key={index}>
        {this.renderImageCard(image, index)}
      </SwiperSlide>
    );
  }

  get filteredImages() {
    return this.props.getFilteredImages();
  }

  imageSorter(a, b) {
    return b.place[0] - a.place[0];
  }

  handleNextSlide() {
    this.swiperRef.current.swiper.slideNext(300);
  }
  handlePrevSlide() {
    this.swiperRef.current.swiper.slidePrev(300);
  }

  renderEmbedded() {
    const delay = 5000;
    return (
      <Swiper
      navigation={false}
      autoplay={{ delay }}
      modules={[Autoplay]}
        className={clsx(styles.Swiper, styles.EmbeddedSwiper)}
        ref={this.swiperRef}
      >
        {
          this.filteredImages.sort(this.imageSorter).map(this.renderSwiperImageCard)
        }
      </Swiper>
    );
  }

  renderSwiper() {
    return (
      <GlobalHotKeys keyMap={this.keyMap} handlers={this.handlers}>
        <Header />
          <Swiper
            pagination={true}
            navigation={true}
            modules={[Pagination, Navigation]}
            className={styles.Swiper}
            ref={this.swiperRef}
          >
            {
              this.filteredImages.sort(this.imageSorter).map(this.renderSwiperImageCard)
            }
          </Swiper>
        <Footer />
      </GlobalHotKeys>
    );
  }

  renderNoResultsContent() {
    return (
      <div className={styles.Showcase}>
          <Header />
          <div className={styles.Showcase}>
            <div className={clsx(styles.helpText, styles.lonely)}>
              <SearchIcon fontSize="large" />
              <Typography variant="h6" color="inherit" component="div">
                Apparently this was too hard for you...
              </Typography>
            </div>
          </div>
        <Footer />
      </div>
    );
  }

  renderScoreSheet() {

    const columns = [
      {
        field: 'place', headerName: 'Place', width: 200
      },
      {
        field: 'category', headerName: 'Category', width: 200
      },
      {
        field: 'name', headerName: 'Name', width: 330
      },
      {
        field: 'title', headerName: 'Title', width: 400
      },
      {
        field: 'totalVotes', headerName: 'Total Votes', width: 100, type: 'number',
      },
    ];

    const images =
      this.filteredImages
        .sort(this.imageSorter)
        .map(image => {
          return {
            ...image,
            name: image.user.name
          };
        });

    const rows = [
      ...images.filter(image => image.category === 'monochrome'),
      ...images.filter(image => image.category === 'color'),
    ];

    const handlePrint = () => {
      openGridPrintView(
        this.props.title,
        columns,
        rows,
      );
    };

    const handleOpenDirectory = () => {
      // TODO: we should switch over to initiative like we do for interclub
      //  and use initiative id instead of year and link the competitions together
      //  by initiative id rather than a year...
      const year = /.*-(.*)-.*/.exec(this.props.campaign.id)[1];
      this.props.openDirectory(year);
    };

    const handleOpenStandings = () => {
      // TODO: we should switch over to initiative like we do for interclub
      //  and use initiative id instead of year and link the competitions together
      //  by initiative id rather than a year...
      const year = /.*-(.*)-.*/.exec(this.props.campaign.id)[1];
      this.props.openStandings(year);
    };

    const scoreSheetKeyMap = {
      Print: 'command+p',
    };

    const scoreSheetHandlers = {
      Print: handlePrint.bind(this)
    };

    return (
      <GlobalHotKeys keyMap={scoreSheetKeyMap} handlers={scoreSheetHandlers}>
        <div className={styles.Showcase}>
          <Header/>
          <Box className={styles.scoreSheet}>
            <Box>
              <DataGrid
                sx={{
                  backgroundColor: 'white'
                }}
                rows={rows}
                columns={columns}
                autoPageSize
              />
            </Box>
            <Box sx={{ marginLeft: '8px', height: '4em', display: 'flex', alignItems: 'center' }}>
              <Button sx={{ height: '4em' }} onClick={handleOpenDirectory}>Directory</Button>
              <Button sx={{ height: '4em' }} onClick={handleOpenStandings}>Standings</Button>
              <Button sx={{ height: '4em' }} onClick={handlePrint}>Print</Button>
            </Box>
          </Box>
          <Footer onPrint={handlePrint}/>
        </div>
      </GlobalHotKeys>
    );

  }

  render() {
    let content;
    if (this.props.initializing) {
      content = (
        <div className={styles.Showcase}>
          <Header/>
            <div className={styles.loader}>
              <RingLoader className={styles.loader} color="white" />
            </div>
          <Footer/>
        </div>
      );
    } else if (this.filteredImages.length === 0) {
      content = this.renderNoResultsContent();
    } else if (this.props.showScoreSheet) {
      content = this.renderScoreSheet();
    } else if (this.props.history.location.pathname.startsWith("/embed")) {
      content = this.renderEmbedded();
    } else {
      content = this.renderSwiper();
    }

    return content;
  }
}


WinnersShowcaseView.propTypes = {
  initializing: PropTypes.bool.isRequired,
  getFilteredImages: PropTypes.func,
  showScoreSheet: PropTypes.bool,
  title: PropTypes.string,
  openDirectory: PropTypes.func,
  openStandings: PropTypes.func,
  campaign: PropTypes.object,
};

WinnersShowcaseView.defaultProps = {
  title: 'Winners Showcase',
  initializing: true,
  showScoreSheet: false,
  getFilteredImages: () => { },
  openDirectory: () => { },
  openStandings: () => { },
};

WinnersShowcaseView.connector = (state) => {
  const initializing = !state.appState.ready;
  const showScoreSheet = state.appState.view === 'graded';
  const campaignId = state.appState.config.currentCampaign;
  const campaign = state.appState.config.campaigns[campaignId];
  const title = campaign ? (campaign.title || campaign.name) : 'Winners Showcase';

  return {
    initializing,
    showScoreSheet,
    title,
    campaign,
    getFilteredImages: () => {
      return getFilteredImages(state);
    }
  };
};

WinnersShowcaseView.commander = (dispatch) => {
  return {
    openDirectory: (id) => dispatch({ type: actions.NAVIGATE, payload: { location: `/directory/${id}`, method: 'push' } }),
    openStandings: (id) => dispatch({ type: actions.NAVIGATE, payload: { location: `/standings/${id}`, method: 'push' } }),
  };
};


export default connect(
  WinnersShowcaseView.connector,
  WinnersShowcaseView.commander
)(WinnersShowcaseView);
