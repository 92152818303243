import styles from './ComingSoon.module.scss';

const ComingSoon = () => {
  return (
    <div className={styles.comingSoonView}>
      <div className={styles.helpText}>
        Polls Are Currently Closed
      </div>
    </div>);
};

export default ComingSoon;
