import lodash from 'lodash';
import * as actions from '../actions';
import constants from '../../constants';

import { isVotingEnabled, isVotingOpen, hasVotingEnded } from '../getters';

export const VIEWS = [
  'grid',
  'carousel',
  'graded',
];

export const EXPANDED_STATES = [
  'expanded',
  'condensed',
];

const DEFAULT_CATEGORIES = [
  'monochrome',
  'color',
];

export const VIEW_CATEGORIES = {
  all: 'all',
  voted: 'voted'
};

const DEFAULT_MAX_VOTES = 3;

const DEFAULT_STATE = {
  filter: {
    content: '',
  },

  view: VIEWS[0],

  expanded: EXPANDED_STATES[0],

  connectionStatus: {
    status: 'disconnected',
    serviceEndPoint: constants.API_ENDPOINT,
  },

  config: {
    campaigns: {
      [constants.DEFAULT_CAMPAIGN]: {
        id: constants.DEFAULT_CAMPAIGN,
        name: 'Competition to end all competitions',
        title: 'Darkroomers Final Competition',
        description: 'You cannot enter. All your image belong to us',
        rules: 'http://norules.com',
        schema: '/nope.json',
        maxEntriesPerUser: 0,
        minTitleLength: 1,
        startDate: 'December 1, 1970',
        endDate: 'December 31, 1970',
        categories: DEFAULT_CATEGORIES,
        votingStartDate: 'December 2, 1970',
        votingEndDate: 'December 31, 2099',
        votingInstructions: 'Vote for up to 3 color and 3 monochrome image. You may change your votes up to the voting end date by returning to this site. The theme for the competition is Explosions...'

      }
    },
    defaultCampaign: constants.DEFAULT_CAMPAIGN,
    defaultMaxVotesPerCategory: DEFAULT_MAX_VOTES,
    currentCampaign: constants.DEFAULT_CAMPAIGN,
    // FIXME: this appears to be unused
    galleryCampaigns: [],
  },
  ready: false,
  category: VIEW_CATEGORIES.all,
};

function updateReadyState(state, ready = true) {
  const newState = {
    ...state,
    ready,
  };
  return newState;
}

function setActiveView(state, view) {
  if (VIEWS.includes(view)) {
    return {
      ...state,
      view,
    };

  }
  return state;
}


function setExpandedState(state, expanded) {
  if (EXPANDED_STATES.includes(expanded)) {
    return {
      ...state,
      expanded,
    };

  }
  return state;
}


function resetFilters(state) {
  return {
    ...state,
    category: state.config.campaigns[state.config.currentCampaign].categories[0],
  };
}

function setActiveCategory(state, category) {
  const campCategories = state.config.currentCampaign ?
    state.config.campaigns[state.config.currentCampaign].categories
   : [];
  const validCategories = [
    '',
    ...Object.values(VIEW_CATEGORIES),
    ...campCategories,
  ];

  if (validCategories.includes(category)) {
      return {
        ...state,
        category,
      };
    }
    return state;
}


function updateServerConfiguration(state, config) {
  const newState = { ...state };
  newState.config.currentCampaign = config.currentCampaign || constants.DEFAULT_CAMPAIGN;
  newState.config.defaultCampaign = config.currentCampaign || constants.DEFAULT_CAMPAIGN;
  newState.config.campaigns = lodash.filter(config.campaigns, isVotingEnabled).reduce((acc, campaign) => {
    acc[campaign.id] = campaign;
    return acc;
  }, {});

  const campaign = lodash.find(newState.config.campaigns, isVotingOpen);
  newState.config.galleryCampaigns = Object.values(newState.config.campaigns).filter(hasVotingEnded).map(campaign => campaign.id);
  newState.config.currentCampaign = (campaign && campaign.id) || newState.config.galleryCampaigns.slice(-1).pop() || lodash.get(newState.config.galleryCampaigns, '0');
  newState.config.imageServiceProvider = config.imageServiceProvider;
  return newState;
}

function setActiveCampaign(state, campaign) {
  const newState = { ...state };
  newState.config.currentCampaign = campaign;
  return newState;
}

function updateConnectionStatus(state, status) {
  const newState = { ...state };
  newState.connectionStatus = {
    ...state.connectionStatus,
    ...status
  };
  return newState;
}

const appState = (state = DEFAULT_STATE, action) => {
  let newState;

  switch (action.type) {

    case actions.RESET_ALL_FILTERS:
      newState = resetFilters(state);
      break;

    case actions.SET_ACTIVE_CATEGORY:
      newState = setActiveCategory(state, action.payload.category);
      break;

    case actions.SET_ACTIVE_VIEW:
      newState = setActiveView(state, action.payload.view);
      break;

    case actions.SET_EXPANDED_STATE:
      newState = setExpandedState(state, action.payload.expanded);
      break;

    case actions.SET_ACTIVE_CAMPAIGN:
      newState = setActiveCampaign(state, action.payload.campaign);
      break;

    case actions.UPDATE_HEALTH:
      newState = updateConnectionStatus(state, action.payload);
      break;

    case actions.SERVER_CONFIG:
      newState = updateServerConfiguration(state, action.payload.config);
      break;

    case actions.UPDATE_READY_STATE:
      newState = updateReadyState(state, lodash.get(action, 'payload.ready', true));
      break;

    default:
      return state;
  }


  return newState;
};

export default appState;
