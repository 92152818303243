import { hasVotedForImageByID, getVoteCountForCategoryByCampaign } from './reducers/ballot';
import { VIEW_CATEGORIES } from './reducers/app-state';

import { isDateWithinDateRange } from '../Utils/DateUtils';

export const getBallot = (state) => {
  return state.ballot.toJS();
};

export function getCampaigns(state) {
  return state.appState.config.campaigns;
}

export function getGalleryCampaignsForYear(state, year) {
  return state.appState.config.galleryCampaigns
    .map(campaignId => state.appState.config.campaigns[campaignId])
    .filter(camp => isDateWithinDateRange(camp.startDate, `January 1, ${year}`, `December 31, ${year}`));
}

export function getCurrentCampaign(state) {
  const campaignId = state.appState.config.currentCampaign;
  return state.appState.config.campaigns[campaignId];
}

export function hasVotedForImage(state, image) {
  return hasVotedForImageByID(state.ballot, image.id);
}

export function getFilteredImages(state) {
  const currentCategory = state.appState.category;
  const images = Object.values(state.images);
  return images.filter((image) => {
    if (currentCategory === VIEW_CATEGORIES.all) {
      return true;
    }
    if (currentCategory === VIEW_CATEGORIES.voted) {
      return hasVotedForImage(state, image);
    }
    return image.category === currentCategory;
  });
}

export function isAppReady(state) {
  return state.appState.ready;
}

export function testAddingMoreVotesToCategory(state, category) {
  const campaignId = state.appState.config.currentCampaign;
  const camp = state.appState.config.campaigns[campaignId];
  const max = camp.maxVotesPerCategory || state.appState.config.defaultMaxVotesPerCategory;

  if (getVoteCountForCategoryByCampaign(state.ballot, category, campaignId) < max) {
    return null;
  }

  return {
    message: `You can only vote for ${max} images in ${category}`,
  };
}